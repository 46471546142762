import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import PageContainer from '../../containers/PageContainer'
import LanguageContext from '../../contexts/LanguageContext/LanguageContext'
import LoadingContext from '../../contexts/LoadingContext/LoadingContext'
import UserContext from '../../contexts/UserContext/UserContext'
import { useAxios } from '../../hooks/useAxios'
import { useFullScreenLayout } from '../../hooks/useFullScreenLayout'
import { translate } from '../../localization/localizationUtils'
import ReclamationListModel from '../../types/models/Reclamations/ReclamationListModel'
import ReclamationsTable from './ReclamationsTable'

const Reclamations = () => {
  useFullScreenLayout(true)
  const { currentLanguage } = useContext(LanguageContext)
  const [reclamations, setReclamations] = useState<ReclamationListModel[]>([])
  const [filteredReclamations, setFilteredReclamations] =
    useState<ReclamationListModel[]>(reclamations)
  const [showResolved, setShowResolved] = useState<boolean>(false)
  const { setLoading } = useContext(LoadingContext)
  const { axiosGet } = useAxios()
  const { user } = useContext(UserContext)

  const loadReclamations = useCallback(() => {
    axiosGet<ReclamationListModel[]>(
      `/Reclamation/Index?rada=${user?.rangeId}&lang=${currentLanguage}`,
      (data) => {
        setReclamations(data)
      },
      (l) => setLoading(l, 'reclamationsIndex')
    )
  }, [user, axiosGet, setLoading, currentLanguage])

  useEffect(() => {
    loadReclamations()
  }, [loadReclamations])

  useEffect(() => {
    showResolved
      ? setFilteredReclamations(reclamations)
      : setFilteredReclamations(reclamations.filter((r) => !r.resolved))
  }, [reclamations, showResolved])

  return (
    <PageContainer fullWidth>
      <div className="ms-auto">
        <Form.Check
          label={translate('reclamations-ShowResolved')}
          value={showResolved ? 'true' : 'false'}
          onChange={() => setShowResolved(!showResolved)}
        />
      </div>
      <ReclamationsTable reclamations={filteredReclamations} />
    </PageContainer>
  )
}

export default Reclamations
