type Props = {
  fullWidth?: boolean
  children?: React.ReactNode
}

const PageContainer = ({ fullWidth, children }: Props) => {
  return (
    <div
      id="main-container"
      className="h-100 w-100 d-flex flex-column overflow-auto"
    >
      {children}
    </div>
  )
}

export default PageContainer
