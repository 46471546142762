import React from 'react'
import { FaPlus } from 'react-icons/fa'
import BnfTablePaged from '../../comps/BnfTable/BnfTablePaged'
import IBnfTableDefColumn from '../../comps/BnfTable/IBnfTableDefColumn'
import StockListModel from '../../types/models/Stock/StockListModel'

type Props = {
  stock: StockListModel[]
  onAddStockItem?: (item: StockListModel) => void
}

const StockTable = ({ stock, onAddStockItem }: Props) => {
  const tableDef: Array<IBnfTableDefColumn> = [
    {
      caption: '',
      fieldName: 'addButton',
      isVisible: onAddStockItem ? true : false,
      sortType: 'none',
      filterType: 'none',
      valueFormat: ({ cell }: any) => {
        return (
          <div
            role="button"
            className="link d-flex align-items-center w-100 h-100"
            style={{ marginRight: '1rem' }}
            onClick={() =>
              onAddStockItem &&
              onAddStockItem(cell.row?.values as StockListModel)
            }
          >
            <FaPlus />
          </div>
        )
      },
    },
    {
      caption: 'Stock Id',
      fieldName: 'stockId',
      isVisible: false,
    },
    {
      caption: 'stock-Card',
      fieldName: 'cardId',
      maxWidthPx: 100,
    },
    {
      caption: 'stock-CardExt',
      fieldName: 'cardIdExt',
      isVisible: false,
    },
    {
      caption: 'X',
      fieldName: 'x',
      isVisible: false,
    },
    {
      caption: 'Y',
      fieldName: 'y',
      isVisible: false,
    },
    {
      caption: 'X Label 1',
      fieldName: 'xLabel1',
      isVisible: false,
    },
    {
      caption: 'X Description 1',
      fieldName: 'xDescription1',
      isVisible: false,
    },
    {
      caption: 'Y Label 1',
      fieldName: 'yLabel1',
      isVisible: false,
    },
    {
      caption: 'Y Description 1',
      fieldName: 'yDascription1',
      isVisible: false,
    },
    {
      caption: 'Y Description 2',
      fieldName: 'yDescription2',
      isVisible: false,
    },
    {
      caption: 'Name',
      fieldName: 'name',
      isVisible: false,
    },
    {
      caption: 'stock-Name',
      fieldName: 'nameFull',
    },
    {
      caption: 'stock-Model',
      fieldName: 'model',
    },
    {
      caption: 'stock-Kind',
      fieldName: 'kindId',
    },
    {
      caption: 'stock-Type',
      fieldName: 'typeId',
    },
    {
      caption: 'stock-Collection',
      fieldName: 'collectionId',
    },
    {
      caption: 'stock-Category',
      fieldName: 'categoryId',
    },
    {
      caption: 'stock-Brand',
      fieldName: 'brandId',
    },
    {
      caption: 'stock-Ean',
      fieldName: 'ean',
    },
    {
      caption: 'Creation Date',
      fieldName: 'creationDate',
      isVisible: false,
    },
    {
      caption: 'Vat Id',
      fieldName: 'vatId',
      isVisible: false,
    },
    {
      caption: 'Unit Id',
      fieldName: 'unitId',
      isVisible: false,
    },
    {
      caption: 'Location',
      fieldName: 'location',
      isVisible: false,
    },
    {
      caption: 'Amount Total',
      fieldName: 'amountTotal',
      isVisible: false,
    },
    {
      caption: 'Amount Reserved',
      fieldName: 'amountReserved',
      isVisible: false,
    },
    {
      caption: 'stock-Amount',
      fieldName: 'amountDisp',
      align: 'right',
      sortType: 'num',
      filterType: 'none',
    },
    {
      caption: 'Amount Supplier',
      fieldName: 'amountSupplier',
      isVisible: false,
    },
    {
      caption: 'Amount Ordered',
      fieldName: 'amountOrdered',
      isVisible: false,
    },
    {
      caption: 'Amount Stocked In',
      fieldName: 'amountStockedIn',
      isVisible: false,
    },
    {
      caption: 'Stock Price',
      fieldName: 'stockPrice',
      isVisible: false,
    },
  ]

  return (
    <BnfTablePaged
      source={stock}
      columnsDef={tableDef}
      defaultSort={[{ id: 'nameFull' }]}
      downloadableExcel={onAddStockItem ? false : true}
      excelName="stock"
    />
  )
}

export default StockTable
