import React, { ReactNode } from 'react'

type Props = {
  children?: ReactNode
}

const PageHeader = ({ children }: Props) => {
  return <div className="mb-3 page-header">{children}</div>
}

export default PageHeader
