import React, { useCallback, useContext, useEffect, useState } from 'react'
import LoadingContext from '../../../contexts/LoadingContext/LoadingContext'
import UserContext from '../../../contexts/UserContext/UserContext'
import { useAxios } from '../../../hooks/useAxios'
import { translate } from '../../../localization/localizationUtils'
import StockNoteModel from '../../../types/models/Stock/StockNoteModel'
import ReclamationStockNoteTable from './ReclamationStockNoteTable'
import LanguageContext from '../../../contexts/LanguageContext/LanguageContext'

type Props = {
  recYear: number
  recRange: string
  recId: number
  lastUpdate?: string
}

const ReclamationStockNotes = ({
  recYear,
  recRange,
  recId,
  lastUpdate,
}: Props) => {
  const { currentLanguage } = useContext(LanguageContext)
  const [stockNotes, setStockNotes] = useState<StockNoteModel[]>([])
  const { setLoading } = useContext(LoadingContext)
  const { axiosGet } = useAxios()
  const { user } = useContext(UserContext)

  const loadStockNotes = useCallback(() => {
    axiosGet<StockNoteModel[]>(
      `/Stock/StockNotes?recYear=${recYear}&recRange=${recRange}&recId=${recId}&stockId=${user?.stockId}&pohyb=${user?.pohybId}&lang=${currentLanguage}`,
      (data) => {
        setStockNotes(data)
      },
      (l) => setLoading(l, 'stockNotes')
    )
  }, [recYear, recRange, recId, user, currentLanguage, axiosGet, setLoading])

  useEffect(() => {
    loadStockNotes()
  }, [loadStockNotes, lastUpdate])

  return (
    <section>
      {stockNotes.length === 0 && (
        <div className="w-100 alert alert-warning">
          {translate('stockNotes-EmptyWarning')}
        </div>
      )}
      {stockNotes.map((sn) => (
        <div key={sn.stockNoteId} className="mb-3">
          <h6 className="mb-3">{sn.stockNoteId}</h6>
          <ReclamationStockNoteTable items={sn.stockItems || []} />
          <hr />
        </div>
      ))}
    </section>
  )
}

export default ReclamationStockNotes
