import React, { useContext } from 'react'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import UserContext from '../../contexts/UserContext/UserContext'
import { translate } from '../../localization/localizationUtils'
import LanguageSelector from '../LanguageSelector/LanguageSelector'
import './TopMenu.scss'

const TopMenu = () => {
  const { user, logout } = useContext(UserContext)

  return (
    <div id="top-menu-wrapper">
      <Navbar className="navbar-top">
        <LinkContainer to="/">
          <Navbar.Brand className="m-0 p-0 d-flex align-items-center">
            <img
              src="/images/Logo.svg"
              className="me-3 d-inline-block align-text-center"
              alt="logo"
            ></img>
            {translate('nav-Reclamations')}
          </Navbar.Brand>
        </LinkContainer>
        <div className="ms-auto d-flex">
          {user && (
            <div>
              <Nav>
                <NavDropdown title={user.name} className="nav-dropdown">
                  <NavDropdown.Item
                    onClick={logout}
                    className="d-flex justify-content-center"
                  >
                    {translate('general-Logout')}
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </div>
          )}
          <div className="ms-1">
            <LanguageSelector />
          </div>
        </div>
      </Navbar>
    </div>
  )
}

export default TopMenu
