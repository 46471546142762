import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'

export const booleanValueFormat = ({ cell: { value } }: any) => {
  return (
    <>
      {value ? (
        <FaCheckCircle className="success" />
      ) : (
        <FaTimesCircle className="danger" />
      )}
    </>
  )
}
