import { useState } from 'react'
import LayoutContext from './LayoutContext'

type Props = {
  children: React.ReactNode
}

const LayoutProvider = ({ children }: Props) => {
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false)

  return (
    <LayoutContext.Provider
      value={{
        isFullScreen,
        setFullScreen: setIsFullScreen,
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}

export default LayoutProvider
