import { useContext, useEffect } from 'react'
import LayoutContext from '../contexts/LayoutContext/LayoutContext'

export const useFullScreenLayout = (fullScreen: boolean) => {
  const { setFullScreen } = useContext(LayoutContext)

  useEffect(() => {
    setFullScreen(fullScreen)
  }, [setFullScreen, fullScreen])

  return {}
}
