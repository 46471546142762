import { createContext } from 'react'

interface ILanguageContext {
  currentLanguage: string
  changeLanguage: (newLang: string) => void
}

const LanguageContext = createContext<ILanguageContext>({
  currentLanguage: '',
  changeLanguage: (newLang: string) => undefined,
})

export default LanguageContext
