import React from 'react'
import { FaAddressCard } from 'react-icons/fa'
import BnfTablePaged from '../../comps/BnfTable/BnfTablePaged'
import IBnfTableDefColumn from '../../comps/BnfTable/IBnfTableDefColumn'
import PartnerListModel from '../../types/models/Partners/PartnerListModel'

type Props = {
  partners: PartnerListModel[]
  onClickAddressCount: (id: string, partnerName: string) => void
}

const PartnersTable = ({ partners, onClickAddressCount }: Props) => {
  const tableDef: Array<IBnfTableDefColumn> = [
    {
      caption: 'partners-Id',
      fieldName: 'id',
      maxWidthPx: 70,
    },
    {
      caption: 'partners-Name',
      fieldName: 'name',
    },
    {
      caption: 'partners-Ico',
      fieldName: 'ico',
    },
    {
      caption: 'partners-Address',
      fieldName: 'addressFull',
    },
    {
      caption: 'partners-AddressInvoiceStreet',
      fieldName: 'addressInvoiceStreet',
      isVisible: false,
    },
    {
      caption: 'partners-AddressInvoiceCity',
      fieldName: 'addressInvoiceCity',
      isVisible: false,
    },
    {
      caption: 'partners-AddressInvoiceZip',
      fieldName: 'addressInvoiceZip',
      isVisible: false,
    },
    {
      caption: 'partners-AddressInvoiceCountry',
      fieldName: 'addressInvoiceCountryId',
      isVisible: false,
    },
    {
      caption: 'partners-Email',
      fieldName: 'addressInvoiceEmail',
    },
    {
      caption: 'partners-Phone',
      fieldName: 'addressInvoicePhone',
    },
    {
      caption: 'partners-Contact',
      fieldName: 'addressInvoiceContact',
    },
    {
      caption: 'partners-Country',
      fieldName: 'countryId',
      filterType: 'select',
    },
    {
      caption: 'partners-Currency',
      fieldName: 'currencyId',
      filterType: 'select',
    },
    {
      caption: 'partners-Region',
      fieldName: 'regionId',
      filterType: 'select',
      isVisible: false,
    },
    {
      caption: 'partners-Segment',
      fieldName: 'segmentId',
      filterType: 'select',
      isVisible: false,
    },
    {
      caption: 'partners-AddressCount',
      fieldName: 'addressCount',
      sortType: 'none',
      filterType: 'none',
      align: 'center',
      valueFormat: ({ cell }: any) => {
        return (
          <div
            role="button"
            className="link"
            onClick={() => {
              cell?.row?.values?.id &&
                onClickAddressCount(
                  cell?.row?.values?.id,
                  cell?.row?.values?.name
                )
            }}
          >
            <FaAddressCard />
          </div>
        )
      },
    },
  ]

  return (
    <BnfTablePaged
      source={partners}
      columnsDef={tableDef}
      defaultSort={[{ id: 'name' }]}
      downloadableExcel
      excelName="partners"
    />
  )
}

export default PartnersTable
