import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { localizedStrings } from './localizedStrings'

const buildResources = () => {
  return {
    cz: {
      translations: localizedStrings
        .filter((s) => s.cz)
        .reduce((obj, cur) => ({ ...obj, [cur.key]: cur.cz || '' }), {}),
    },
    pl: {
      translations: localizedStrings
        .filter((s) => s.pl)
        .reduce((obj, cur) => ({ ...obj, [cur.key]: cur.pl || '' }), {}),
    },
  }
}

i18n.use(LanguageDetector).init({
  resources: buildResources(),
  fallbackLng: 'cz',
  ns: ['translations'],
  defaultNS: 'translations',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
})

export default i18n
