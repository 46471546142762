import React, { ReactNode, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import SideMenu from '../comps/SideMenu/SideMenu'
import TopMenu from '../comps/TopMenu/TopMenu'
import LayoutContext from '../contexts/LayoutContext/LayoutContext'
import { routesWithoutSideMenu } from './routesWithoutSideMenu'

type Props = {
  children?: ReactNode
}

const MainLayout = ({ children }: Props) => {
  const { isFullScreen } = useContext(LayoutContext)
  const location = useLocation()
  return (
    <main className="h-100">
      <div className="bg-main h-100 d-flex flex-column overflow-hidden">
        <div>
          <TopMenu />
        </div>
        <div
          className={`h-100 m-0 p-0 d-flex flex-row overflow-hidden ${
            isFullScreen ? '' : 'container-lg'
          }`}
        >
          {!routesWithoutSideMenu.some((r) =>
            location.pathname?.includes(r)
          ) && <SideMenu />}

          {children}
        </div>
      </div>
    </main>
  )
}

export default MainLayout
