import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import LoadingContext from '../../../contexts/LoadingContext/LoadingContext'
import { useAxios } from '../../../hooks/useAxios'
import { translate } from '../../../localization/localizationUtils'
import EmailTemplateModel from '../../../types/models/EmailService/EmailTemplateModel'

type Props = {
  show: boolean
  language: string
  onConfirm: (templateId: number) => void
  onClose: () => void
}

const EmailTemplateModal = ({ show, language, onConfirm, onClose }: Props) => {
  const [templates, setTemplates] = useState<EmailTemplateModel[]>([])
  const [selectedTemplateId, setSelectedTemplateId] = useState<number>()
  const { axiosGet } = useAxios()
  const { setLoading } = useContext(LoadingContext)

  const loadTemplates = useCallback(() => {
    axiosGet<EmailTemplateModel[]>(
      `/EmailService/Templates?modelId=REK&langId=${language}`,
      (data) => {
        setTemplates(data)
      },
      (l) => setLoading(l, 'emailTemplates')
    )
  }, [language, axiosGet, setLoading])

  useEffect(() => {
    loadTemplates()
  }, [loadTemplates])

  useEffect(() => {
    show && setSelectedTemplateId(undefined)
  }, [show])

  return (
    <Modal show={show} size="lg" centered onHide={() => onClose()}>
      <Modal.Header closeButton>
        <Modal.Title>
          {translate('emailTemplates-TemplateSelector')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {templates.map((t) => (
          <div className="mb-3" key={t.id}>
            <Form.Check
              type="radio"
              radioGroup="templateSelector"
              value={t.id}
              onChange={(e) => setSelectedTemplateId(Number(e.target?.value))}
              checked={selectedTemplateId === t.id}
              label={t.name}
            />
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-primary"
          onClick={() => selectedTemplateId && onConfirm(selectedTemplateId)}
        >
          {translate('emailTemplates-ChooseTemplate')}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default EmailTemplateModal
