import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import LoadingContext from '../../contexts/LoadingContext/LoadingContext'
import { useAxios } from '../../hooks/useAxios'
import PartnerAddressListModel from '../../types/models/Partners/PartnerAddressListModel'
import PartnerAddressesTable from './PartnerAddressesTable'

type Props = {
  partnerName: string
  id: string
  show: boolean
  onClose: () => void
}

const PartnerAddressesModal = ({ partnerName, id, show, onClose }: Props) => {
  const [addresses, setAddresses] = useState<PartnerAddressListModel[]>([])
  const { axiosGet } = useAxios()
  const { setLoading } = useContext(LoadingContext)

  const loadAddresses = useCallback(() => {
    setAddresses([])
    if (!id) return
    axiosGet<PartnerAddressListModel[]>(
      `/Partner/GetPartAddresses?parCislo=${id}`,
      (data) => {
        setAddresses(data)
      },
      (l) => setLoading(l, 'partnersAddresses')
    )
  }, [id, axiosGet, setLoading])

  useEffect(() => {
    loadAddresses()
  }, [loadAddresses])

  return (
    <Modal show={show} size="lg" centered onHide={() => onClose()}>
      <Modal.Header closeButton>
        <Modal.Title>{partnerName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="overflow-auto">
          <PartnerAddressesTable addresses={addresses} />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default PartnerAddressesModal
