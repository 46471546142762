import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Form, Tab, Tabs } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import FormItem from '../../../comps/FormItem/FormItem'
import PageHeader from '../../../comps/PageHeader/PageHeader'
import PageContainer from '../../../containers/PageContainer'
import LoadingContext from '../../../contexts/LoadingContext/LoadingContext'
import { useAxios } from '../../../hooks/useAxios'
import ReclamationDetailModel from '../../../types/models/Reclamations/ReclamationDetailModel'
import { fromApiToFormControl } from '../../../utils/DateTime/dateTimeUtils'
import { translate } from '../../../localization/localizationUtils'
import ImageViewer from 'react-simple-image-viewer'
import EmailTemplateModal from './EmailTemplateModal'
import NotificationsContext from '../../../contexts/NotificationsContext/NotificationsContext'
import { NotificationType } from '../../../types/models/NotificationModel'
import NewStockNoteModal from './NewStockNoteModal'
import StockListModel from '../../../types/models/Stock/StockListModel'
import StockNoteModel from '../../../types/models/Stock/StockNoteModel'
import { useFullScreenLayout } from '../../../hooks/useFullScreenLayout'
import UserContext from '../../../contexts/UserContext/UserContext'
import ReclamationStockNotes from './ReclamationStockNotes'
import { FaAddressBook, FaEnvelope, FaPhone } from 'react-icons/fa'
import LanguageContext from '../../../contexts/LanguageContext/LanguageContext'

const ReclamationDetail = () => {
  useFullScreenLayout(false)
  const { currentLanguage } = useContext(LanguageContext)
  const { year, range, recId } = useParams<{
    year: string
    range: string
    recId: string
  }>()
  const [reclamation, setReclamation] = useState<ReclamationDetailModel>()
  const [noteTemplate, setNoteTemplate] = useState<number>()
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)
  const [showEmailTemplateModal, setShowEmailTemplateModal] =
    useState<boolean>(false)
  const [showNewStockNoteModal, setShowNewStockNoteModal] =
    useState<boolean>(false)
  const [lastStockNoteUpdate, setLastStockNoteUpdate] = useState<string>()
  const { setLoading } = useContext(LoadingContext)
  const { axiosGet, axiosPostNoResult, axiosPost } = useAxios()
  const { addNotification } = useContext(NotificationsContext)
  const { user } = useContext(UserContext)
  const navigate = useNavigate()

  const loadReclamation = useCallback(() => {
    axiosGet<ReclamationDetailModel>(
      `/Reclamation/Detail?rok=${year}&rada=${range}&cislo=${recId}&lang=${currentLanguage}`,
      (data) => {
        setReclamation(data)
      },
      (l) => setLoading(l, 'reclamationDetail')
    )
  }, [year, range, recId, currentLanguage, axiosGet, setLoading])

  const saveReclamation = async (): Promise<boolean> => {
    return axiosPostNoResult(
      '/Reclamation/Detail',
      reclamation,
      undefined,
      (l) => setLoading(l, 'reclamationUpdate')
    )
  }

  const saveCreateStockNote = async (
    stockNote: StockNoteModel
  ): Promise<string | null> => {
    return axiosPost<string>(
      '/Stock/CreateStockNote',
      stockNote,
      undefined,
      (l) => setLoading(l, 'stockNoteCreate')
    )
  }

  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }, [])

  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }

  const onSelectEmailTemplate = (templateId: number) => {
    setShowEmailTemplateModal(false)
    templateId &&
      navigate(
        `/email/${templateId}/${`${year};${range};${recId}`}/${
          reclamation?.contactEmail
        }`
      )
  }

  const onNewStockNote = async (items: StockListModel[]) => {
    setShowNewStockNoteModal(false)
    if (!items || items.length === 0) return
    const stockNote: StockNoteModel = {
      reclamationYear: reclamation?.year,
      reclamationRange: reclamation?.range,
      reclamationId: Number(recId),
      pohybId: user?.pohybId,
      language: user?.language,
      stockItems: items,
    }
    const result = await saveCreateStockNote(stockNote)
    if (result !== null) {
      setLastStockNoteUpdate(Date.now?.toString())
      addNotification({
        type: NotificationType.Success,
        name: translate('reclamation-StockNoteCreatedSuccessfully'),
        text: '',
      })
    }
  }

  const onSaveChanges = async () => {
    if (await saveReclamation()) {
      addNotification({
        type: NotificationType.Success,
        name: translate('reclamation-SavedSuccessfully'),
        text: '',
      })
    }
  }

  useEffect(() => {
    loadReclamation()
  }, [loadReclamation])

  return (
    <>
      <PageContainer>
        <PageHeader>#{recId}</PageHeader>
        {reclamation && (
          <Tabs defaultActiveKey="reclamation" className="mb-3">
            <Tab
              eventKey="reclamation"
              title={translate('reclamation-TabReclamation')}
            >
              <div className="d-flex text-small text-darker mb-3">
                <span className="d-flex align-items-center">
                  <FaAddressBook className="me-1" /> {reclamation.contactName}
                </span>
                <span className="ms-5 d-flex align-items-center">
                  <FaEnvelope className="me-1" />
                  {reclamation.contactEmail}
                </span>
                <span className="ms-5 d-flex align-items-center">
                  <FaPhone className="me-1" />
                  {reclamation.contactPhone}
                </span>
              </div>
              <div className="row gx-0 w-100">
                <section className="col-7">
                  <div className="mb-5">
                    <div className="row mb-2">
                      <FormItem
                        label="reclamation-DateAcquired"
                        className="col-6"
                      >
                        <Form.Control
                          type="date"
                          value={fromApiToFormControl(reclamation.date || '')}
                          readOnly={true}
                        />
                      </FormItem>
                      <FormItem label="reclamation-State" className="col-6">
                        <Form.Select
                          value={reclamation.state || ''}
                          onChange={(e) =>
                            setReclamation({
                              ...reclamation,
                              state: e.target?.value,
                              selectedStateId:
                                reclamation.statuses?.find(
                                  (s) => s.name === e.target?.value
                                )?.id || '',
                            })
                          }
                          className="highlighted"
                        >
                          <option value="">
                            {translate('reclamation-ChooseState')}
                          </option>
                          {reclamation.statuses?.map((s) => (
                            <option key={s.id} value={s.name}>
                              {s.name}
                            </option>
                          ))}
                        </Form.Select>
                        {reclamation.settlementDate && (
                          <div className="text-small text-darker m-1">
                            {translate('reclamation-ResolvedDay')}{' '}
                            {reclamation.settlementDate}
                          </div>
                        )}
                      </FormItem>
                    </div>
                    <div className="row mb-2">
                      <FormItem
                        label="reclamation-Subscriber"
                        className="col-6"
                      >
                        <Form.Control
                          type="text"
                          value={reclamation.subscriberName || ''}
                          readOnly={true}
                        />
                        <div className="text-small text-darker m-1">
                          <div>{reclamation.subscriberCountry}</div>
                          <div>{reclamation.subscriberCity}</div>
                          <div>{reclamation.subscriberStreet}</div>
                          <div>{reclamation.subscriberZip}</div>
                        </div>
                      </FormItem>
                      <FormItem label="reclamation-Recipient" className="col-6">
                        <Form.Control
                          type="text"
                          value={reclamation.recipientName || ''}
                          readOnly={true}
                        />
                        <div className="text-small text-darker m-1">
                          <div>{reclamation.recipientCountry}</div>
                          <div>{reclamation.recipientCity}</div>
                          <div>{reclamation.recipientStreet}</div>
                          <div>{reclamation.recipientZip}</div>
                        </div>
                      </FormItem>
                    </div>
                    <div className="row mb-2">
                      <FormItem label="reclamation-Product" className="col-10">
                        <Form.Control
                          type="text"
                          value={reclamation.productName || ''}
                          readOnly={true}
                        />
                      </FormItem>
                      <FormItem label="reclamation-Size" className="col-2">
                        <Form.Control
                          type="text"
                          value={reclamation.productPosXLabel || ''}
                          readOnly={true}
                        />
                      </FormItem>
                    </div>
                    <div className="row mb-2">
                      <FormItem label="reclamation-DateSold" className="col-6">
                        <Form.Control
                          type="date"
                          value={fromApiToFormControl(
                            reclamation.dateOfSale || ''
                          )}
                          readOnly={true}
                        />
                      </FormItem>
                      <FormItem
                        label="reclamation-SerialNumber"
                        className="col-6"
                      >
                        <Form.Control
                          type="text"
                          value={reclamation.serialNumber || ''}
                          readOnly={true}
                        />
                      </FormItem>
                    </div>
                    <div className="mb-5">
                      <FormItem label="reclamation-DefectDescription">
                        <Form.Control
                          as="textarea"
                          rows={5}
                          value={reclamation.defectDescription || ''}
                          className="h-100"
                          readOnly={true}
                        />
                      </FormItem>
                    </div>
                    <div className="row mb-2">
                      <FormItem label="reclamation-Invoice" className="col-6">
                        <Form.Control
                          type="text"
                          value={`${reclamation.invoiceYear || '-'}/${
                            reclamation.invoiceId || '-'
                          }`}
                          readOnly={true}
                        />
                      </FormItem>
                      <FormItem label="reclamation-Price" className="col-6">
                        <Form.Control
                          type="text"
                          value={`${reclamation.price?.toString() || '-'} ${
                            reclamation.currency || ''
                          }`}
                          readOnly={true}
                        />
                      </FormItem>
                    </div>
                    <div className="row mb-5">
                      <FormItem
                        label="reclamation-Settlement"
                        className="col-6"
                      >
                        <Form.Select
                          value={reclamation.resolution || ''}
                          onChange={(e) =>
                            setReclamation({
                              ...reclamation,
                              resolution: e.target?.value,
                              selectedResolutionId:
                                reclamation.resolutions?.find(
                                  (r) => r.name === e.target?.value
                                )?.id || '',
                            })
                          }
                          className="highlighted"
                        >
                          <option value="">
                            {translate('reclamation-ChooseSettlement')}
                          </option>
                          {reclamation.resolutions?.map((r) => (
                            <option key={r.id} value={r.name}>
                              {r.name}
                            </option>
                          ))}
                        </Form.Select>
                      </FormItem>
                      <FormItem
                        label="reclamation-CreditNote"
                        className="col-6"
                      >
                        <Form.Control
                          type="text"
                          value={reclamation.creditNote || ''}
                          readOnly={true}
                        />
                      </FormItem>
                    </div>
                    <div className="mb-2">
                      <Form.Check
                        label={translate('reclamation-SramReported')}
                        checked={reclamation.sramReported}
                        onChange={() =>
                          setReclamation({
                            ...reclamation,
                            sramReported: !reclamation.sramReported,
                          })
                        }
                      />
                    </div>
                    <div className="mb-2">
                      <FormItem label="reclamation-EndUserNote">
                        <Form.Control
                          as="textarea"
                          rows={5}
                          className="h-100"
                          value={reclamation.endUserNote || ''}
                          readOnly={true}
                        />
                      </FormItem>
                    </div>
                    <div className="mb-2">
                      <FormItem label="reclamation-NoteTemplate">
                        <Form.Select
                          value={noteTemplate}
                          onChange={(e) => {
                            const templateId = Number(e.target?.value)
                            setNoteTemplate(templateId)
                            setReclamation({
                              ...reclamation,
                              note: reclamation.noteTemplates?.find(
                                (t) => t.id === templateId
                              )?.text,
                            })
                          }}
                          className="highlighted"
                        >
                          <option value="">
                            {translate('reclamation-ChooseTemplate')}
                          </option>
                          {reclamation.noteTemplates?.map((t, index) => (
                            <option key={index} value={t.id}>
                              {t.name}
                            </option>
                          ))}
                        </Form.Select>
                      </FormItem>
                    </div>
                    <div className="mb-2">
                      <FormItem label="reclamation-Note">
                        <Form.Control
                          as="textarea"
                          rows={5}
                          className="h-100 highlighted"
                          value={reclamation.note || ''}
                          onChange={(e) =>
                            setReclamation({
                              ...reclamation,
                              note: e.target?.value,
                            })
                          }
                        />
                      </FormItem>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <button className="btn btn-primary" onClick={onSaveChanges}>
                      {translate('reclamation-Save')}
                    </button>
                    <div className="d-flex">
                      {/* <button className="btn btn-outline-secondary">
                        {translate('reclamation-Pdf')}
                      </button> */}
                      <button
                        className="btn btn-outline-secondary ms-3"
                        onClick={() => setShowEmailTemplateModal(true)}
                      >
                        {translate('reclamation-SendEmail')}
                      </button>
                    </div>
                  </div>
                </section>
                <section className="col-5 ps-3">
                  <div id="images-viewer-wrapper">
                    <section className="gallery">
                      {reclamation.photos?.map((photo, index) => (
                        <div className="image-container" key={index}>
                          <img
                            className="image"
                            src={photo.thumbnailUrl}
                            onClick={() => openImageViewer(index)}
                            alt={`Fotografie ${photo.id}`}
                          />
                          <div className="mt-2 text-center text-darker text-small">
                            {photo.note}
                          </div>
                        </div>
                      ))}
                    </section>
                    {isViewerOpen && (
                      <ImageViewer
                        src={reclamation.photos?.map((p) => p.url || '') || []}
                        currentIndex={currentImage}
                        disableScroll={false}
                        closeOnClickOutside={true}
                        onClose={closeImageViewer}
                      />
                    )}
                  </div>
                </section>
              </div>
            </Tab>
            <Tab
              eventKey="stockNotes"
              title={translate('reclamation-TabStockNotes')}
            >
              <ReclamationStockNotes
                recYear={Number(year)}
                recRange={range || ''}
                recId={Number(recId)}
                lastUpdate={lastStockNoteUpdate}
              />
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-primary"
                  onClick={() => setShowNewStockNoteModal(true)}
                >
                  {translate('reclamation-StockNoteCreate')}
                </button>
              </div>
            </Tab>
          </Tabs>
        )}
      </PageContainer>
      <EmailTemplateModal
        show={showEmailTemplateModal}
        language={reclamation?.recipientLanguage || user?.language || ''}
        onConfirm={onSelectEmailTemplate}
        onClose={() => setShowEmailTemplateModal(false)}
      />
      <NewStockNoteModal
        show={showNewStockNoteModal}
        onConfirm={onNewStockNote}
        onClose={() => setShowNewStockNoteModal(false)}
      />
    </>
  )
}

export default ReclamationDetail
