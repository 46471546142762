import React, { useContext, useState } from 'react'
import { FloatingLabel, Form } from 'react-bootstrap'
import PageContainer from '../../containers/PageContainer'
import LoadingContext from '../../contexts/LoadingContext/LoadingContext'
import UserContext from '../../contexts/UserContext/UserContext'
import Qs from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom'
import { translate } from '../../localization/localizationUtils'
import { useFullScreenLayout } from '../../hooks/useFullScreenLayout'

const Login = () => {
  useFullScreenLayout(true)
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const { setLoading } = useContext(LoadingContext)
  const { login } = useContext(UserContext)
  const location = useLocation()
  const navigate = useNavigate()

  const loginHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true, 'loggingIn')
    await login(email, password)
    setLoading(false, 'loggingIn')
    const returnUrl = Qs.parse(
      location.search?.substring(1)
    )?.returnUrl?.toString()
    navigate(returnUrl ? returnUrl : '/')
  }

  return (
    <PageContainer fullWidth>
      <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center">
        <Form onSubmit={loginHandler}>
          <Form.Group>
            <FloatingLabel label={translate('login-Email')} className="mb-1">
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              ></Form.Control>
            </FloatingLabel>
            <FloatingLabel label={translate('login-Password')} className="mb-2">
              <Form.Control
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </FloatingLabel>
          </Form.Group>
          <button type="submit" className="btn btn-primary w-100">
            {translate('login-Submit')}
          </button>
        </Form>
      </div>
    </PageContainer>
  )
}

export default Login
