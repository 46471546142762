import React, { ReactNode } from 'react'
import { FloatingLabel } from 'react-bootstrap'
import { translate } from '../../localization/localizationUtils'

type Props = {
  label: string
  className?: string
  children?: ReactNode
}

const FormItem = ({ label, className, children }: Props) => {
  return (
    <div className={className}>
      <FloatingLabel label={translate(label)} className="h-100">
        {children}
      </FloatingLabel>
    </div>
  )
}

export default FormItem
