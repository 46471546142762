import { ReactElement } from 'react'
import { FaAddressBook, FaBook, FaWarehouse } from 'react-icons/fa'

export interface NavItem {
  label: string
  icon: ReactElement
  to: string
  children?: NavItem[]
}

export const NavigationItems: NavItem[] = [
  {
    label: 'nav-Reclamations',
    icon: <FaBook />,
    to: '/reclamations',
  },
  {
    label: 'nav-Stock',
    icon: <FaWarehouse />,
    to: '/stock',
  },
  {
    label: 'nav-Partners',
    icon: <FaAddressBook />,
    to: '/partners',
  },
]
