import axios, { CancelTokenSource } from 'axios'
import { useEffect, useRef } from 'react'

export const useCancelToken = () => {
  const axiosSource = useRef<CancelTokenSource>(axios.CancelToken.source())
  const isCancel = axios.isCancel

  useEffect(
    () => () => {
      axiosSource.current.cancel()
    },
    []
  )

  return {
    cancelToken: axiosSource.current.token,
    isCancel,
  }
}
