export interface LocalizedString {
  key: string
  cz?: string
  pl?: string
}

export const localizedStrings: LocalizedString[] = [
  {
    key: 'general-Yes',
    cz: 'Ano',
    pl: 'Tak',
  },
  {
    key: 'general-No',
    cz: 'Ne',
    pl: 'Nie',
  },
  {
    key: 'general-Logout',
    cz: 'Odhlásit',
    pl: 'Wyloguj',
  },
  {
    key: 'notifications-Error',
    cz: 'Chyba',
    pl: 'Błąd',
  },
  {
    key: 'notifications-UnexpectedErrorOccured',
    cz: 'Došlo k neočekávané chybě při komunikaci se serverem',
    pl: 'Wystąpił nieoczekiwany błąd w trakcie komunikacji z serwerem',
  },
  {
    key: 'nav-Partners',
    cz: 'Partneři',
    pl: 'Partnerzy',
  },
  {
    key: 'nav-Reclamations',
    cz: 'Reklamace',
    pl: 'Reklamacje',
  },
  {
    key: 'nav-Stock',
    cz: 'Sklad',
    pl: 'Magazyn',
  },
  {
    key: 'login-Email',
    cz: 'Emailová Adresa',
    pl: 'Email',
  },
  {
    key: 'login-Password',
    cz: 'Heslo',
    pl: 'Hasło',
  },
  {
    key: 'login-Submit',
    cz: 'Přihlásit',
    pl: 'Zaloguj się',
  },
  {
    key: 'filter-All',
    cz: 'Vše',
    pl: 'Wszystko',
  },
  {
    key: 'table-PageXofY',
    cz: 'Stránka {{page}} z {{totalPages}}',
    pl: 'Strona {{page}} z {{totalPages}}',
  },
  {
    key: 'table-ShowPage',
    cz: 'Zobrazit stránku',
    pl: 'Wyświetl stronę',
  },
  {
    key: 'table-Show',
    cz: 'Zobrazit',
    pl: 'Wyświetl',
  },
  {
    key: 'partners-Id',
    cz: 'Id',
    pl: 'ID',
  },
  {
    key: 'partners-Name',
    cz: 'Název',
    pl: 'Nazwa',
  },
  {
    key: 'partners-Ico',
    cz: 'IČ',
    pl: 'REGON',
  },
  {
    key: 'partners-Address',
    cz: 'Fakturační Adresa',
    pl: 'Adres do faktury',
  },
  {
    key: 'partners-Email',
    cz: 'Email',
    pl: 'Email',
  },
  {
    key: 'partners-Phone',
    cz: 'Telefon',
    pl: 'Telefon',
  },
  {
    key: 'partners-Contact',
    cz: 'Kontakt',
    pl: 'Kontakt',
  },
  {
    key: 'partners-Country',
    cz: 'Země',
    pl: 'Kraj',
  },
  {
    key: 'partners-Currency',
    cz: 'Měna',
    pl: 'Waluta',
  },
  {
    key: 'partners-Region',
    cz: 'Region',
    pl: 'Region',
  },
  {
    key: 'partners-Segment',
    cz: 'Segment',
    pl: 'Segment',
  },
  {
    key: 'partners-AddressCount',
    cz: 'Adresy',
    pl: 'Adresy',
  },
  {
    key: 'partnerAddresses-Street',
    cz: 'Ulice',
    pl: 'Ulica',
  },
  {
    key: 'partnerAddresses-City',
    cz: 'Město',
    pl: 'Miasto',
  },
  {
    key: 'partnerAddresses-Zip',
    cz: 'Zip',
    pl: 'Kod pocztowy',
  },
  {
    key: 'partnerAddresses-Country',
    cz: 'Země',
    pl: 'Kraj',
  },
  {
    key: 'partnerAddresses-Email',
    cz: 'Email',
    pl: 'Email',
  },
  {
    key: 'partnerAddresses-Phone',
    cz: 'Telefon',
    pl: 'Telefon',
  },
  {
    key: 'partnerAddresses-Contact',
    cz: 'Kontakt',
    pl: 'Kontakt',
  },
  {
    key: 'stock-Card',
    cz: 'Karta',
    pl: 'Karta',
  },
  {
    key: 'stock-Name',
    cz: 'Název',
    pl: 'Nazwa',
  },
  {
    key: 'stock-Model',
    cz: 'Model',
    pl: 'Model',
  },
  {
    key: 'stock-Kind',
    cz: 'Druh',
    pl: 'Rodzaj',
  },
  {
    key: 'stock-Type',
    cz: 'Typ',
    pl: 'Typ',
  },
  {
    key: 'stock-Collection',
    cz: 'Kolekce',
    pl: 'Kolekcja',
  },
  {
    key: 'stock-Category',
    cz: 'Kategorie',
    pl: 'Kategoria',
  },
  {
    key: 'stock-Brand',
    cz: 'Značka',
    pl: 'Marka',
  },
  {
    key: 'stock-Ean',
    cz: 'EAN',
    pl: 'EAN',
  },
  {
    key: 'stock-Amount',
    cz: 'Množství',
    pl: 'Ilość',
  },
  {
    key: 'reclamations-Id',
    cz: 'Číslo',
    pl: 'Numer',
  },
  {
    key: 'reclamations-Date',
    cz: 'Datum',
    pl: 'Data',
  },
  {
    key: 'reclamations-State',
    cz: 'Stav',
    pl: 'Stan',
  },
  {
    key: 'reclamations-Resolved',
    cz: 'Vyřešeno',
    pl: 'Rozwiązane',
  },
  {
    key: 'reclamations-Sram',
    cz: 'SRAM',
    pl: 'SRAM',
  },
  {
    key: 'reclamations-Partner',
    cz: 'Partner název',
    pl: 'Klient',
  },
  {
    key: 'reclamations-Product',
    cz: 'Název zboží',
    pl: 'Nazwa produktu',
  },
  {
    key: 'reclamations-Note',
    cz: 'Poznámka',
    pl: 'Notatka',
  },
  {
    key: 'reclamations-Description',
    cz: 'Popis',
    pl: 'Opis',
  },
  {
    key: 'reclamations-ShowResolved',
    cz: 'zobrazit vyřešené',
    pl: 'Wyświetl rozwiązane',
  },
  {
    key: 'reclamation-DateAcquired',
    cz: 'Datum pořízení',
    pl: 'Data zakupu',
  },
  {
    key: 'reclamation-State',
    cz: 'Stav',
    pl: 'Stan',
  },
  {
    key: 'reclamation-ResolvedDay',
    cz: 'Vyřízeno dne',
    pl: 'Rozwiązane w dniu',
  },
  {
    key: 'reclamation-Subscriber',
    cz: 'Odběratel',
    pl: 'Odbiorca',
  },
  {
    key: 'reclamation-Recipient',
    cz: 'Konečný příjemce',
    pl: 'Klient końcowy',
  },
  {
    key: 'reclamation-Product',
    cz: 'Produkt',
    pl: 'Produkt',
  },
  {
    key: 'reclamation-Size',
    cz: 'Velikost',
    pl: 'Rozmiar',
  },
  {
    key: 'reclamation-DateSold',
    cz: 'Datum prodeje',
    pl: 'Data sprzedaży',
  },
  {
    key: 'reclamation-SerialNumber',
    cz: 'Sériové číslo',
    pl: 'Nr seryjny',
  },
  {
    key: 'reclamation-DefectDescription',
    cz: 'Popis závady',
    pl: 'Opis uszkodzenia',
  },
  {
    key: 'reclamation-Invoice',
    cz: 'Původní faktura',
    pl: 'Oryginalna faktura',
  },
  {
    key: 'reclamation-Price',
    cz: 'Částka',
    pl: 'Kwota',
  },
  {
    key: 'reclamation-Settlement',
    cz: 'Způsob vyřízení',
    pl: 'Sposób rozwiązania',
  },
  {
    key: 'reclamation-CreditNote',
    cz: 'Dobropis',
    pl: 'Faktura korygująca',
  },
  {
    key: 'reclamation-SramReported',
    cz: 'Náhlášeno v SRAM',
    pl: 'Zgłoszone w SRAM',
  },
  {
    key: 'reclamation-EndUserNote',
    cz: 'Poznámka konečného příjemce',
    pl: 'Notatka klienta końcowego',
  },
  {
    key: 'reclamation-NoteTemplate',
    cz: 'Šablona poznámky',
    pl: 'Wzór notatki',
  },
  {
    key: 'reclamation-Note',
    cz: 'Poznámka',
    pl: 'Notatka',
  },
  {
    key: 'reclamation-Save',
    cz: 'Uložit',
    pl: 'Zapisz',
  },
  {
    key: 'reclamation-Pdf',
    cz: 'PDF',
    pl: 'PDF',
  },
  {
    key: 'reclamation-SendEmail',
    cz: 'Odeslat email',
    pl: 'Wyślij email',
  },
  {
    key: 'reclamation-ChooseState',
    cz: 'Vyberte stav',
    pl: 'Wybierz stan',
  },
  {
    key: 'reclamation-ChooseSettlement',
    cz: 'Vyberte způsob vyřízení',
    pl: 'Wybierz sposób rozwiązania',
  },
  {
    key: 'reclamation-ChooseTemplate',
    cz: 'Vyberte šablonu',
    pl: 'Wybierz wzór',
  },
  {
    key: 'reclamation-SavedSuccessfully',
    cz: 'Změny byly úspěšně uloženy',
    pl: 'Zmiany zostały zapisane',
  },
  {
    key: 'reclamation-TabReclamation',
    cz: 'Detail reklamace',
    pl: 'Szczegóły reklamacji',
  },
  {
    key: 'reclamation-TabStockNotes',
    cz: 'Odpisky materiálu',
    pl: 'Odpisy materiału',
  },
  {
    key: 'reclamation-StockNoteCreate',
    cz: 'Vytvořit odpisku',
    pl: 'Stwórz odpis',
  },
  {
    key: 'reclamation-StockNoteCreatedSuccessfully',
    cz: 'Odpiska materiálu byla úspěšně vytvořena',
    pl: 'Odpis wykorzystanego materiału został stworzony',
  },
  {
    key: 'newStockNote-Header',
    cz: 'Odpiska materiálu',
    pl: 'Odpis wykorzystanego materiału',
  },
  {
    key: 'newStockNote-ConfirmSelect',
    cz: 'Vytvořit odpisku',
    pl: 'Stwórz odpis',
  },
  {
    key: 'newStockNote-AddItems',
    cz: 'Přidejte položky',
    pl: 'Dodaj pozycje',
  },
  {
    key: 'stockNoteItem-Amount',
    cz: 'Počet',
    pl: 'Ilość',
  },
  {
    key: 'stockNoteItem-XLabel',
    cz: 'Velikost',
    pl: 'Rozmiar',
  },
  {
    key: 'stockNoteItem-Disp',
    cz: 'disp {{disp}}',
    pl: 'Dostępne {{disp}}',
  },
  {
    key: 'stockNotes-EmptyWarning',
    cz: 'K dané reklamaci neexistují žádné odpisky materiálu',
    pl: 'W danej reklamacji nie ma żadnego odpisu',
  },
  {
    key: 'emailTemplates-TemplateSelector',
    cz: 'Výběr šablony',
    pl: 'Wybór wzoru',
  },
  {
    key: 'emailTemplates-ChooseTemplate',
    cz: 'Zvolit šablonu',
    pl: 'Wybierz wzór',
  },
  {
    key: 'email-From',
    cz: 'Od',
    pl: 'Od',
  },
  {
    key: 'email-To',
    cz: 'Pro',
    pl: 'Dla',
  },
  {
    key: 'email-Copy',
    cz: 'Kopie',
    pl: 'Kopia',
  },
  {
    key: 'email-BlindCopy',
    cz: 'Skrytá kopie',
    pl: 'Ukryta kopia',
  },
  {
    key: 'email-Subject',
    cz: 'Předmět',
    pl: 'Temat',
  },
  {
    key: 'email-Body',
    cz: 'Tělo',
    pl: 'Treść',
  },
  {
    key: 'email-Send',
    cz: 'Odeslat',
    pl: 'Wyślij',
  },
  {
    key: 'email-SentSuccessfully',
    cz: 'Email byl úspěšně odeslán',
    pl: 'Email został wysłany',
  },
]
