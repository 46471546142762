import { createContext } from 'react'

interface ILayoutContext {
  isFullScreen: boolean
  setFullScreen: (fs: boolean) => void
}

const LayoutContext = createContext<ILayoutContext>({
  isFullScreen: false,
  setFullScreen: (fs: boolean) => undefined,
})

export default LayoutContext
