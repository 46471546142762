import axios from 'axios'
import { API_BASE_URL } from '../consts'
import UserModel from '../types/models/Login/UserModel'

export const logout = () => {
  localStorage.removeItem('user')
}

export const login = async (
  email: string,
  password: string
): Promise<UserModel | null> => {
  let user = null
  await axios
    .post<UserModel>(`${API_BASE_URL}/Reclamation/LoginExternal`, {
      login: email,
      password: password,
    })
    .then((d) => {
      user = d.data
    })
    .catch((e) => {
      user = null
    })

  if (user) localStorage.setItem('user', JSON.stringify(user))

  return user
}

export const getUser = () => {
  const u = localStorage.getItem('user')
  return u ? (JSON.parse(u) as UserModel) : null
}

const xmodule = { login, logout, getUser }
export default xmodule
