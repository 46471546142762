import React, { useCallback, useContext, useEffect, useState } from 'react'
import PageContainer from '../../containers/PageContainer'
import LoadingContext from '../../contexts/LoadingContext/LoadingContext'
import UserContext from '../../contexts/UserContext/UserContext'
import { useAxios } from '../../hooks/useAxios'
import { useFullScreenLayout } from '../../hooks/useFullScreenLayout'
import PartnerListModel from '../../types/models/Partners/PartnerListModel'
import PartnerAddressesModal from './PartnerAddressesModal'
import PartnersTable from './PartnersTable'

const Partners = () => {
  useFullScreenLayout(true)
  const [partners, setPartners] = useState<PartnerListModel[]>([])
  const [showPartnerAddressesModal, setShowPartnerAddressesModal] =
    useState<boolean>(false)
  const [partnerAddressesId, setPartnerAddressesId] = useState<string>('')
  const [partnerAddressesName, setPartnerAddressesName] = useState<string>('')
  const { setLoading } = useContext(LoadingContext)
  const { axiosGet } = useAxios()
  const { user } = useContext(UserContext)

  const loadPartners = useCallback(() => {
    axiosGet<PartnerListModel[]>(
      `/Partner/Index?country=${user?.country}`,
      (data) => {
        setPartners(
          data.map((d) => {
            return {
              ...d,
              addressFull: `${d.addressInvoiceStreet}${
                d.addressInvoiceCity ? `, ${d.addressInvoiceCity}` : ''
              }${d.addressInvoiceZip ? `, ${d.addressInvoiceZip}` : ''}${
                d.addressInvoiceCountryId
                  ? `, ${d.addressInvoiceCountryId}`
                  : ''
              }`,
            }
          })
        )
      },
      (l) => setLoading(l, 'partnersIndex')
    )
  }, [user, axiosGet, setLoading])

  const onClickAddressCount = (id: string, partnerName: string) => {
    setShowPartnerAddressesModal(true)
    setPartnerAddressesId(id)
    setPartnerAddressesName(partnerName)
  }

  useEffect(() => {
    loadPartners()
  }, [loadPartners])

  return (
    <>
      <PageContainer fullWidth>
        <PartnersTable
          partners={partners}
          onClickAddressCount={onClickAddressCount}
        />
      </PageContainer>
      <PartnerAddressesModal
        show={showPartnerAddressesModal}
        id={partnerAddressesId}
        partnerName={partnerAddressesName}
        onClose={() => setShowPartnerAddressesModal(false)}
      />
    </>
  )
}

export default Partners
