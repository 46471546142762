import axios from 'axios'
import React from 'react'
import './App.scss'
import { API_BASE_URL } from './consts'
import LoadingProvider from './contexts/LoadingContext/LoadingProvider'
import NotificationsProvider from './contexts/NotificationsContext/NotificationsProvider'
import Router from './services/Router'
import { I18nextProvider } from 'react-i18next'
import i18n from './localization/i18n'
import UserProvider from './contexts/UserContext/UserProvider'
import LayoutProvider from './contexts/LayoutContext/LayoutProvider'
import LanguageProvider from './contexts/LanguageContext/LanguageProvider'

const InitAxios = () => {
  axios.defaults.baseURL = API_BASE_URL

  axios.interceptors.request.use((config) => {
    if (config.headers !== undefined) {
      config.headers['jwt-token'] =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzeXN0ZW0iOiIxMDAwIn0.HKGbbQH7PK1NcHDDxR3ratlO3oIvaF3wnWElfzeks9E'
    }
    // eslint-disable-next-line  no-console
    console.debug('axios URL:' + config.url)
    return config
  })
}

function App() {
  InitAxios()

  return (
    <I18nextProvider i18n={i18n}>
      <UserProvider>
        <LanguageProvider>
          <LayoutProvider>
            <LoadingProvider>
              <NotificationsProvider>
                <Router />
              </NotificationsProvider>
            </LoadingProvider>
          </LayoutProvider>
        </LanguageProvider>
      </UserProvider>
    </I18nextProvider>
  )
}

export default App
