import React, { useCallback, useContext, useEffect, useState } from 'react'
import PageContainer from '../../containers/PageContainer'
import LanguageContext from '../../contexts/LanguageContext/LanguageContext'
import LoadingContext from '../../contexts/LoadingContext/LoadingContext'
import UserContext from '../../contexts/UserContext/UserContext'
import { useAxios } from '../../hooks/useAxios'
import { useFullScreenLayout } from '../../hooks/useFullScreenLayout'
import StockListModel from '../../types/models/Stock/StockListModel'
import StockTable from './StockTable'

type Props = {
  onAddStockItem?: (item: StockListModel) => void
}

const Stock = ({ onAddStockItem }: Props) => {
  useFullScreenLayout(!onAddStockItem)
  const { currentLanguage } = useContext(LanguageContext)
  const [stock, setStock] = useState<StockListModel[]>([])
  const { setLoading } = useContext(LoadingContext)
  const { axiosGet } = useAxios()
  const { user } = useContext(UserContext)

  const loadStock = useCallback(() => {
    axiosGet<StockListModel[]>(
      `/Stock/Index?stockId=${user?.stockId}&lang=${currentLanguage}`,
      (data) => {
        setStock(data)
      },
      (l) => setLoading(l, 'stockIndex')
    )
  }, [user, axiosGet, setLoading, currentLanguage])

  useEffect(() => {
    loadStock()
  }, [loadStock])

  if (onAddStockItem) {
    return <StockTable stock={stock} onAddStockItem={onAddStockItem} />
  }

  return (
    <PageContainer fullWidth>
      <StockTable stock={stock} />
    </PageContainer>
  )
}

export default Stock
