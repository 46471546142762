import React, { useContext } from 'react'
import { Nav, NavDropdown } from 'react-bootstrap'
import LanguageContext from '../../contexts/LanguageContext/LanguageContext'

const LanguageSelector = () => {
  const { currentLanguage, changeLanguage } = useContext(LanguageContext)

  const changeLg = (lg: string) => {
    changeLanguage(lg)
    localStorage.setItem('language', lg)
  }

  return (
    <Nav>
      <NavDropdown
        title={
          <img
            style={{ height: '1rem', marginLeft: '.5rem' }}
            alt={currentLanguage}
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${currentLanguage.toUpperCase()}.svg`}
          />
        }
        className="nav-dropdown"
      >
        {['cz', 'pl'].map((lg, index) => (
          <NavDropdown.Item key={index} onClick={() => changeLg(lg)}>
            <div className="w-100 d-flex justify-content-center">
              <img
                style={{ border: '1px solid black', maxHeight: '1rem' }}
                alt={lg}
                src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${lg.toUpperCase()}.svg`}
              />
            </div>
          </NavDropdown.Item>
        ))}
      </NavDropdown>
    </Nav>
  )
}

export default LanguageSelector
