import React, { useContext } from 'react'
import { Toast, ToastContainer } from 'react-bootstrap'
import { FaTimes } from 'react-icons/fa'
import NotificationsContext from '../../contexts/NotificationsContext/NotificationsContext'
import { NotificationType } from '../../types/models/NotificationModel'
import './ToastNotifications.scss'

const ToastNotifications = () => {
  const { notifications, removeNotification } = useContext(NotificationsContext)

  const getBg = (type: NotificationType): string => {
    switch (type) {
      case NotificationType.Danger:
        return 'danger'
      case NotificationType.Success:
        return 'success'
      case NotificationType.Warning:
        return 'warning'
      default:
        return ''
    }
  }

  return (
    <div id="toast-notification-wrapper">
      <ToastContainer position="top-center" className="top-container">
        {notifications
          .filter((n) => n.type !== NotificationType.Success)
          .map((n) => {
            return (
              <div className="toast-wrapper" key={n.id}>
                <Toast
                  bg={getBg(n.type)}
                  onClose={() => removeNotification(n.id)}
                  className="fade-bottom"
                >
                  <div className="p-1 d-flex justify-content-end">
                    <button
                      className="button-close"
                      onClick={() => removeNotification(n.id)}
                    >
                      <FaTimes color="white" />
                    </button>
                  </div>
                  <Toast.Body className="mb-3">
                    <div className="d-flex justify-content-center align-items-center">
                      <h5>{n.name}</h5>
                    </div>
                    <div className="d-flex justify-content-center align-items-center text-center">
                      {n.text}
                    </div>
                  </Toast.Body>
                </Toast>
              </div>
            )
          })}
      </ToastContainer>
      <ToastContainer position="bottom-center" className="bottom-container">
        {notifications
          .filter((n) => n.type === NotificationType.Success)
          .map((n) => {
            return (
              <div className="toast-wrapper" key={n.id}>
                <Toast
                  bg={getBg(n.type)}
                  onClose={() => removeNotification(n.id)}
                  className="fade-top"
                >
                  <div className="p-1 d-flex justify-content-end">
                    <button
                      className="button-close"
                      onClick={() => removeNotification(n.id)}
                    >
                      <FaTimes color="white" />
                    </button>
                  </div>
                  <Toast.Body className="mb-3">
                    <div className="d-flex justify-content-center align-items-center">
                      <h5>{n.name}</h5>
                    </div>
                    <div className="d-flex justify-content-center align-items-center text-center">
                      {n.text}
                    </div>
                  </Toast.Body>
                </Toast>
              </div>
            )
          })}
      </ToastContainer>
    </div>
  )
}

export default ToastNotifications
