import axios, { AxiosRequestConfig, CancelToken } from 'axios'
import { useCallback } from 'react'
import { useCancelToken } from './useCancelToken'

export const useAxios = () => {
  const { cancelToken } = useCancelToken()

  const axiosGet = useCallback(
    <T,>(
      url: string,
      successfulCallback: (data: T) => void,
      setLoading?: (state: boolean) => void,
      cancelTok?: CancelToken,
      errorCallback?: (error: any) => void
    ) => {
      setLoading && setLoading(true)
      axios
        .get<T>(url, {
          cancelToken: cancelTok || cancelToken,
        })
        .then((response) => {
          successfulCallback(response.data)
          setLoading && setLoading(false)
        })
        .catch((error) => {
          setLoading && setLoading(false)
          if (axios.isCancel(error)) return
          console.log(error)
          errorCallback && errorCallback(error)
        })
    },
    [cancelToken]
  )

  const axiosGetNoResult = useCallback(
    async (
      url: string,
      setLoading?: (state: boolean) => void,
      cancelTok?: CancelToken
    ): Promise<boolean> => {
      let result = false
      setLoading && setLoading(true)
      await axios
        .get(url, {
          cancelToken: cancelTok || cancelToken,
        })
        .then((response) => {
          result = true
          setLoading && setLoading(false)
        })
        .catch((error) => {
          result = false
          setLoading && setLoading(false)
          if (axios.isCancel(error)) return
          console.log(error)
        })
      return result
    },
    [cancelToken]
  )

  const axiosPost = useCallback(
    async <T,>(
      url: string,
      content: any,
      successfulCallback?: (data: T) => void,
      setLoading?: (state: boolean) => void,
      config?: AxiosRequestConfig,
      errorCallback?: (error: any) => void
    ): Promise<T | null> => {
      let result = null
      setLoading && setLoading(true)
      await axios
        .post<T>(url, content, config)
        .then((response) => {
          result = response.data
          successfulCallback && successfulCallback(response.data)
          setLoading && setLoading(false)
        })
        .catch((error) => {
          result = null
          setLoading && setLoading(false)
          if (axios.isCancel(error)) return
          console.log(error)
          errorCallback && errorCallback(error)
        })
      return result
    },
    []
  )

  const axiosPostNoResult = useCallback(
    async (
      url: string,
      content: any,
      successfulCallback?: () => void,
      setLoading?: (state: boolean) => void,
      config?: AxiosRequestConfig,
      errorCallback?: (error: any) => void
    ): Promise<boolean> => {
      let result = false
      setLoading && setLoading(true)
      await axios
        .post(url, content, config)
        .then((response) => {
          result = true
          successfulCallback && successfulCallback()
          setLoading && setLoading(false)
        })
        .catch((error) => {
          result = false
          setLoading && setLoading(false)
          if (axios.isCancel(error)) return
          console.log(error)
          errorCallback && errorCallback(error)
        })
      return result
    },
    []
  )

  const axiosDelete = useCallback(
    async (
      url: string,
      setLoading?: (state: boolean) => void,
      cancelTok?: CancelToken
    ) => {
      let result = false
      setLoading && setLoading(true)
      await axios
        .delete(url, {
          cancelToken: cancelTok || cancelToken,
        })
        .then((response) => {
          result = true
          setLoading && setLoading(false)
        })
        .catch((error) => {
          result = false
          setLoading && setLoading(false)
          if (axios.isCancel(error)) return
          console.log(error)
        })
      return result
    },
    [cancelToken]
  )

  return {
    axiosGet,
    axiosGetNoResult,
    axiosPost,
    axiosPostNoResult,
    axiosDelete,
  }
}
