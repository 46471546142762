import { useContext, useEffect, useState } from 'react'
import LanguageContext from './LanguageContext'
import i18n from '../../localization/i18n'
import { translate } from '../../localization/localizationUtils'
import UserContext from '../UserContext/UserContext'

type Props = {
  children: React.ReactNode
}

const LanguageProvider = ({ children }: Props) => {
  const { user } = useContext(UserContext)
  const [currentLanguage, setCurrentLanguage] = useState<string>(
    localStorage.getItem('language') || user?.language?.toLowerCase() || 'cz'
  )

  const changeLanguage = (newLanguage: string) => {
    setCurrentLanguage(newLanguage)
  }

  useEffect(() => {
    i18n.changeLanguage(currentLanguage)
    document.title = translate('nav-Reclamations')
  }, [currentLanguage])

  useEffect(() => {
    changeLanguage(
      localStorage.getItem('language') || user?.language?.toLowerCase() || 'cz'
    )
  }, [user])

  return (
    <LanguageContext.Provider
      value={{
        currentLanguage,
        changeLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}

export default LanguageProvider
