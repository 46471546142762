import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { translate } from '../../../localization/localizationUtils'
import StockListModel from '../../../types/models/Stock/StockListModel'
import Stock from '../../Stock/Stock'
import './NewStockNoteModal.scss'
import NewStockNoteTable from './NewStockNoteTable'

type Props = {
  show: boolean
  onConfirm: (items: StockListModel[]) => void
  onClose: () => void
}

const NewStockNoteModal = ({ show, onConfirm, onClose }: Props) => {
  const [items, setItems] = useState<StockListModel[]>([])

  const onAddStockItem = (item: StockListModel) => {
    const indexFound = items.findIndex((i) => {
      return (
        i.stockId === item.stockId &&
        i.cardId === item.cardId &&
        i.cardIdExt === item.cardIdExt &&
        i.x === item.x &&
        i.y === item.y
      )
    })
    if (indexFound !== -1) {
      const i = items[indexFound]
      if (i.amountInput === i.amountDisp) return
      items[indexFound] = { ...i, amountInput: (i.amountInput || 0) + 1 }
    } else {
      items.push({ ...item, amountInput: 1 })
    }
  }

  const onAmountChange = (
    amountNew: number,
    stockId?: string,
    cardId?: string,
    cardIdExt?: string,
    x?: number,
    y?: number
  ) => {
    const indexFound = items.findIndex((i) => {
      return (
        i.stockId === stockId &&
        i.cardId === cardId &&
        i.cardIdExt === cardIdExt &&
        i.x === x &&
        i.y === y
      )
    })
    if (indexFound !== -1) {
      if (amountNew <= 0)
        setItems(items.filter((_, index) => index !== indexFound))
      if (amountNew > (items[indexFound].amountDisp || 0)) return
      else {
        const i = items[indexFound]
        items[indexFound] = { ...i, amountInput: amountNew }
      }
    }
  }

  useEffect(() => {
    show && setItems([])
  }, [show])

  return (
    <Modal
      show={show}
      size="xl"
      centered
      onHide={() => onClose()}
      id="new-stock-note-wrapper"
    >
      <Modal.Header closeButton>
        <Modal.Title>{translate('newStockNote-Header')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="h-100 overflow-hidden">
        <div className="pb-3" style={{ height: '30%' }}>
          {items.length === 0 ? (
            <div className="h-100 background-lightest d-flex align-items-center justify-content-center">
              {translate('newStockNote-AddItems')}
            </div>
          ) : (
            <NewStockNoteTable items={items} onAmountChange={onAmountChange} />
          )}
        </div>
        <div style={{ height: '70%' }}>
          <Stock onAddStockItem={onAddStockItem} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary" onClick={() => onConfirm(items)}>
          {translate('newStockNote-ConfirmSelect')}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default NewStockNoteModal
