import { format, parseISO, parse } from 'date-fns'

export const dateTimeValueFormat = ({ cell: { value } }: any) => {
  let formatted = ''
  const isTime = (value as string)?.includes('T')
  try {
    formatted = value
      ? format(
          isTime ? parseISO(value) : parse(value, 'dd.MM.yyyy', new Date()),
          isTime ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy'
        )
      : ''
  } catch (e: any) {
    console.log(e)
  }
  return <>{formatted}</>
}
