import { createContext } from 'react'

interface ILoadingContext {
  isLoading: boolean
  isLoadingSpecific: (key: string) => void
  setLoading: (loading: boolean, key: string) => void
  triggerLoading: (key: string) => void
  stopLoading: (key: string) => void
}

const LoadingContext = createContext<ILoadingContext>({
  isLoading: false,
  isLoadingSpecific: (_) => undefined,
  setLoading: (_, __) => undefined,
  triggerLoading: (_) => undefined,
  stopLoading: (_) => undefined,
})

export default LoadingContext
