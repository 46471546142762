import { ReactElement, useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import UserContext from '../contexts/UserContext/UserContext'

type Props = {
  children: ReactElement
}

const PrivateRoute = ({ children }: Props) => {
  const { user } = useContext(UserContext)
  const location = useLocation()

  if (user) return children
  return (
    <Navigate
      to={{
        pathname: '/login',
        search: `?returnUrl=${encodeURIComponent(location.pathname)}`,
      }}
    />
  )
}

export default PrivateRoute
