import React, { ReactElement, useMemo } from 'react'
import { Form } from 'react-bootstrap'
import { FaCaretDown, FaCaretUp } from 'react-icons/fa'
import {
  Column,
  ColumnInstance,
  HeaderGroup,
  Row,
  useFilters,
  useSortBy,
  useTable,
} from 'react-table'
import { translate } from '../../localization/localizationUtils'
import './BnfTable.scss'
import IBnfTableDefColumn from './IBnfTableDefColumn'
import { ZipCelXConfig, ZipCelXCell } from 'zipcelx'
import generateExcel from 'zipcelx'

type DefaultSortByCol = {
  id: string
  desc?: boolean
}

type Props = {
  source: Array<any>
  columnsDef: Array<IBnfTableDefColumn>
  defaultSort?: Array<DefaultSortByCol>
  downloadableExcel?: boolean
  customCreateExcelConfig?: (
    headerGroups: HeaderGroup<object>[],
    rows: Row<object>[],
    excelName: string
  ) => ZipCelXConfig
  excelName?: string
}

type FilterProps = {
  column: ColumnInstance
}

// Define a default UI for filtering
const DefaultColumnFilter = ({ column }: FilterProps) => {
  return (
    <Form.Control
      className="filter"
      type="search"
      placeholder=""
      value={column.filterValue || ''}
      onChange={(e) => {
        column.setFilter(e.target?.value || undefined) // Set undefined to remove the filter entirely
      }}
      style={{ maxWidth: column.maxWidth ? `${column.maxWidth}px` : 'auto' }}
    />
  )
}

const SelectColumnFilter = ({ column }: FilterProps) => {
  const options = useMemo<string[]>(() => {
    const options = new Set<string>()
    column.preFilteredRows?.forEach((row) => {
      options.add(row.values[column.id])
    })
    return [...options.values()]
  }, [column.id, column.preFilteredRows])

  return (
    <Form.Select
      className="filter"
      value={column.filterValue}
      onChange={(e) => column.setFilter(e.target?.value || undefined)}
      style={{ maxWidth: column.maxWidth ? `${column.maxWidth}px` : 'auto' }}
    >
      <option value="">{translate('filter-All')}</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </Form.Select>
  )
}

const defaultSortFunction = (
  rowA: Row,
  rowB: Row,
  columnId: string,
  desc: boolean
) => {
  const cellA = rowA.values[columnId]?.toLowerCase()
  const cellB = rowB.values[columnId]?.toLowerCase()

  if (cellA > cellB) return 1
  if (cellA < cellB) return -1
  return 0
}

const defaultNumbersSortFunction = (
  rowA: Row,
  rowB: Row,
  columnId: string,
  desc: boolean
) => {
  const cellA = Number(rowA.values[columnId])
  const cellB = Number(rowB.values[columnId])

  if (cellA > cellB) return 1
  if (cellA < cellB) return -1
  return 0
}

const defaultValueFormat = ({ cell: { value } }: any) => {
  return <>{value}</>
}

function getHeader(column: HeaderGroup<object>): ZipCelXCell[] {
  if (column.totalHeaderCount === 1 || !column.totalHeaderCount) {
    return [
      {
        value:
          (column.Header as ReactElement).props?.children?.toString() || '',
        type: 'string',
      },
    ]
  } else {
    const span = [...Array(column.totalHeaderCount - 1)].map((x) => ({
      value: '',
      type: 'string',
    }))
    return [
      {
        value:
          (column.Header as ReactElement).props?.children?.toString() || '',
        type: 'string',
      },
      ...(span as unknown[] as ZipCelXCell[]),
    ]
  }
}

const createExcelConfig = (
  headerGroups: HeaderGroup<object>[],
  rows: Row<object>[],
  name: string
): ZipCelXConfig => {
  const config: ZipCelXConfig = {
    filename: name,
    sheet: {
      data: [],
    },
  }
  const dataSet = config.sheet.data
  const exportedColumns: string[] = []

  headerGroups.forEach((headerGroup) => {
    const headerRow: ZipCelXCell[] = []
    if (headerGroup.headers) {
      headerGroup.headers.forEach((column) => {
        exportedColumns.push(column.id)
        headerRow.push(...(getHeader(column) as unknown[] as ZipCelXCell[]))
      })
    }
    dataSet.push(headerRow)
  })

  if (rows.length > 0) {
    rows.forEach((row) => {
      const dataRow: ZipCelXCell[] = []
      Object.keys(row.values).forEach(
        (id) =>
          exportedColumns.includes(id) &&
          dataRow.push({
            value: row.values[id],
            type: typeof row.values[id] === 'number' ? 'number' : 'string',
          })
      )

      dataSet.push(dataRow)
    })
  }
  return config
}

const BnfTable = ({
  source,
  columnsDef,
  defaultSort,
  customCreateExcelConfig,
  downloadableExcel,
  excelName,
}: Props) => {
  const defaultSortType = useMemo<any>(() => defaultSortFunction, [])
  const defaultNumbersSortType = useMemo<any>(
    () => defaultNumbersSortFunction,
    []
  )
  const projectsData = useMemo<Array<any>>(() => source, [source])
  const columns = useMemo<Array<Column>>(
    () =>
      columnsDef.map((x) => {
        return {
          accessor: x.fieldName,
          Header: (
            <div
              className={`d-inline-flex align-items-center ${
                x.align === 'center'
                  ? 'w-100 justify-content-center'
                  : x.align === 'right'
                  ? 'w-100 justify-content-end'
                  : ''
              }`}
            >
              {translate(x.caption)}
            </div>
          ),
          disableFilters: x.filterType === 'none',
          Filter:
            x.filterType === 'select'
              ? SelectColumnFilter
              : DefaultColumnFilter,
          sortType:
            x.sortType === 'num' ? defaultNumbersSortType : defaultSortType,
          disableSortBy: x.sortType === 'none',
          Cell: (p: any) => (
            <div
              className={`d-flex align-items-center ${
                x.align === 'center'
                  ? 'justify-content-center'
                  : x.align === 'right'
                  ? 'justify-content-end'
                  : ''
              }`}
            >
              {x.valueFormat ? x.valueFormat(p) : defaultValueFormat(p)}
            </div>
          ),
          isVisible: x.isVisible,
          maxWidth: x.maxWidthPx,
        }
      }),
    [defaultSortType, defaultNumbersSortType, columnsDef]
  )

  const defaultColumn = React.useMemo<any>(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const onDownloadExcel = () => {
    const config = customCreateExcelConfig
      ? customCreateExcelConfig(headerGroups, rows, excelName || 'export')
      : createExcelConfig(headerGroups, rows, excelName || 'export')
    generateExcel(config)
  }

  const hiddenCol: string[] = columnsDef
    .filter((x) => !(x.isVisible ?? true))
    .map((x) => x.fieldName)

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: projectsData,
        defaultColumn,
        initialState: {
          hiddenColumns: hiddenCol,
          sortBy: defaultSort || [],
        },
      },
      useFilters,
      useSortBy
    )

  return (
    <div id="bnf-table-wrapper" className="h-100 flex-scrollable-container">
      <section className="h-100 overflow-auto">
        <table {...getTableProps()} className="table table-striped">
          {headerGroups.map((group, index) => {
            return (
              <thead key={index}>
                <tr
                  {...group.getHeaderGroupProps()}
                  className="bnf-table-captions"
                >
                  {group.headers.map((column) => {
                    return (
                      <th key={column.id}>
                        <div
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render('Header')}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <FaCaretDown />
                              ) : (
                                <FaCaretUp />
                              )
                            ) : (
                              ''
                            )}
                          </span>
                        </div>
                      </th>
                    )
                  })}
                </tr>
                <tr className="bnf-table-filters">
                  {group.headers.map((column) => {
                    return (
                      <th key={column.id}>
                        {column.canFilter && column.render('Filter')}
                      </th>
                    )
                  })}
                </tr>
              </thead>
            )
          })}
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} key={row.id}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} key={cell.column.id}>
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </section>
      {downloadableExcel && (
        <section className="pt-3 d-flex justify-content-end align-items-center">
          <button
            className="btn btn-outline-secondary"
            onClick={onDownloadExcel}
          >
            Export XLSX
          </button>
        </section>
      )}
    </div>
  )
}

export default BnfTable
