import { useCallback, useState } from 'react'
import { Set } from 'typescript'
import Loading from '../../comps/Loading/Loading'
import LoadingContext from './LoadingContext'

type Props = {
  children: React.ReactNode
}

const LoadingProvider = ({ children }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [loadingInstances] = useState<Set<string>>(new Set<string>())

  const isLoadingSpecific = (key: string) => {
    return loadingInstances.has(key)
  }

  const setLoading = useCallback((loading: boolean, key: string) => {
    if (loading) triggerLoading(key)
    else stopLoading(key)
  }, [])

  const triggerLoading = (key: string) => {
    loadingInstances.add(key)
    setIsLoading(true)
  }

  const stopLoading = (key: string) => {
    loadingInstances.delete(key)
    if (loadingInstances.size === 0) setIsLoading(false)
  }

  return (
    <LoadingContext.Provider
      value={{
        isLoading,
        isLoadingSpecific,
        setLoading,
        triggerLoading,
        stopLoading,
      }}
    >
      {isLoading && <Loading />}
      {children}
    </LoadingContext.Provider>
  )
}

export default LoadingProvider
