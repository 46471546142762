import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Form } from 'react-bootstrap'
import { FaFileAlt } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import FormItem from '../../comps/FormItem/FormItem'
import PageContainer from '../../containers/PageContainer'
import LoadingContext from '../../contexts/LoadingContext/LoadingContext'
import { useAxios } from '../../hooks/useAxios'
import { useFullScreenLayout } from '../../hooks/useFullScreenLayout'
import { translate } from '../../localization/localizationUtils'
import EmailBuildModel from '../../types/models/EmailService/EmailBuildModel'
import { Editor } from '@tinymce/tinymce-react'
import NotificationsContext from '../../contexts/NotificationsContext/NotificationsContext'
import { NotificationType } from '../../types/models/NotificationModel'

const Email = () => {
  useFullScreenLayout(false)
  const { templateId, pk, to } = useParams<{
    templateId: string
    pk: string
    to: string
  }>()
  const [emailBuild, setEmailBuild] = useState<EmailBuildModel>()
  const editorRef = useRef<Editor>(null)
  const { setLoading } = useContext(LoadingContext)
  const { axiosGet, axiosPostNoResult } = useAxios()
  const { addNotification } = useContext(NotificationsContext)
  const navigate = useNavigate()

  const loadEmailBuild = useCallback(() => {
    axiosGet<EmailBuildModel>(
      `/EmailService/Build?template=${templateId}&pk=${pk}&to=${to}`,
      (data) => {
        setEmailBuild(data)
        editorRef.current?.editor?.setContent(data.body || '')
      },
      (l) => setLoading(l, 'emailBuild')
    )
  }, [templateId, pk, to, axiosGet, setLoading])

  const saveSendEmail = async (): Promise<boolean> => {
    return axiosPostNoResult('/EmailService/Send', emailBuild, undefined, (l) =>
      setLoading(l, 'sendEmail')
    )
  }

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (await saveSendEmail()) {
      addNotification({
        type: NotificationType.Success,
        name: translate('email-SentSuccessfully'),
        text: '',
      })
      navigate(`/reclamations/detail/${pk?.replaceAll(';', '/')}`)
    }
  }

  useEffect(() => {
    loadEmailBuild()
  }, [loadEmailBuild])

  return (
    <PageContainer>
      <Form
        onSubmit={submitHandler}
        className="w-100 h-100 flex-scrollable-container"
      >
        <FormItem label="email-From" className="mb-2">
          <Form.Control
            type="email"
            value={emailBuild?.from || ''}
            onChange={(e) =>
              setEmailBuild({ ...emailBuild, from: e.target?.value })
            }
            required
          />
        </FormItem>
        <FormItem label="email-To" className="mb-2">
          <Form.Control
            type="email"
            value={emailBuild?.target || ''}
            onChange={(e) =>
              setEmailBuild({ ...emailBuild, target: e.target?.value })
            }
            required
          />
        </FormItem>
        <div className="row mb-4">
          <FormItem label="email-Copy" className="col-6">
            <Form.Control
              type="email"
              value={emailBuild?.targetCopy || ''}
              onChange={(e) =>
                setEmailBuild({ ...emailBuild, targetCopy: e.target?.value })
              }
            />
          </FormItem>
          <FormItem label="email-BlindCopy" className="col-6">
            <Form.Control
              type="email"
              value={emailBuild?.blindCopy || ''}
              onChange={(e) =>
                setEmailBuild({ ...emailBuild, blindCopy: e.target?.value })
              }
            />
          </FormItem>
        </div>

        <FormItem label="email-Subject" className="mb-2">
          <Form.Control
            type="text"
            value={emailBuild?.subject || ''}
            onChange={(e) =>
              setEmailBuild({ ...emailBuild, subject: e.target?.value })
            }
          />
        </FormItem>
        <div className="mb-2 h-100">
          <Editor
            ref={editorRef}
            initialValue=""
            init={{
              plugins: 'link lists',
              menubar: false,
              statusbar: false,
              toolbar: `undo redo | 
            bold italic underline removeformat |
            bullist numlist |
            link |
            alignleft aligncenter alignright alignjustify`,
            }}
            onChange={(e) => {
              setEmailBuild({ ...emailBuild, body: e.target?.getContent() })
            }}
          ></Editor>
        </div>

        <div className="mb-4 attachments">
          {emailBuild?.attachments?.map((a, index) => (
            <div key={index} className="attachment-container">
              <FaFileAlt />
              {a.name}
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-end">
          <button type="submit" className="btn btn-primary">
            {translate('email-Send')}
          </button>
        </div>
      </Form>
    </PageContainer>
  )
}

export default Email
