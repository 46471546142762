import { useNavigate } from 'react-router-dom'
import BnfTablePaged from '../../comps/BnfTable/BnfTablePaged'
import IBnfTableDefColumn from '../../comps/BnfTable/IBnfTableDefColumn'
import ReclamationListModel from '../../types/models/Reclamations/ReclamationListModel'
import { booleanValueFormat } from '../../utils/BnfTable/booleanValueFormat'
import { dateTimeValueFormat } from '../../utils/BnfTable/dateTimeValueFormat'
import { maxCharsValueFormat } from '../../utils/BnfTable/maxCharsValueFormat'

type Props = {
  reclamations: ReclamationListModel[]
}

const ReclamationsTable = ({ reclamations }: Props) => {
  const navigate = useNavigate()

  const tableDef: Array<IBnfTableDefColumn> = [
    {
      caption: 'reclamations-Id',
      fieldName: 'reclamationId',
      sortType: 'num',
      valueFormat: ({ cell }: any) => {
        return (
          <div
            role="button"
            className="link d-flex align-items-center"
            style={{ marginRight: '1rem' }}
            onClick={() =>
              navigate(`/reclamations/detail/${cell.row?.values?.id}`)
            }
          >
            {cell.row?.values?.reclamationId}
          </div>
        )
      },
      maxWidthPx: 50,
    },
    {
      caption: 'reclamations-Date',
      fieldName: 'dateOfAcquisition',
      valueFormat: dateTimeValueFormat,
      maxWidthPx: 100,
    },
    {
      caption: 'reclamations-State',
      fieldName: 'state',
      filterType: 'select',
    },
    {
      caption: 'reclamations-Resolved',
      fieldName: 'resolved',
      filterType: 'none',
      sortType: 'none',
      valueFormat: booleanValueFormat,
    },
    {
      caption: 'reclamations-Sram',
      fieldName: 'sramReported',
      filterType: 'none',
      sortType: 'none',
      valueFormat: booleanValueFormat,
    },
    {
      caption: 'reclamations-Partner',
      fieldName: 'partnerName',
    },
    {
      caption: 'reclamations-Product',
      fieldName: 'productName',
      isVisible: false,
    },
    {
      caption: 'reclamations-Note',
      fieldName: 'techDescription',
      valueFormat: maxCharsValueFormat,
    },
    {
      caption: 'reclamations-Description',
      fieldName: 'description',
      valueFormat: maxCharsValueFormat,
    },
    {
      caption: 'reclamations-Id',
      fieldName: 'id',
      isVisible: false,
    },
    // {
    //   caption: 'User Reclamation Id',
    //   fieldName: 'userRecId',
    // },
    // {
    //   caption: 'Partner City',
    //   fieldName: 'partnerCity',
    // },
    // {
    //   caption: 'Partner Street',
    //   fieldName: 'partnerStreet',
    // },
  ]

  return (
    <BnfTablePaged
      source={reclamations}
      columnsDef={tableDef}
      defaultSort={[{ id: 'reclamationId', desc: true }]}
      downloadableExcel
      excelName="reclamations"
    />
  )
}

export default ReclamationsTable
