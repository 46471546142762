export enum NotificationType {
  Warning,
  Danger,
  Success,
}

export interface NotificationModel {
  id?: number
  type: NotificationType
  name: string
  text: string
  timeAdded?: number
}
