import './Loading.scss'

const Loading = () => {
  return (
    <div id="loading-wrapper">
      <div className="loading">
        <div className="spinner-frame animate-loading">
          <div className="spinner-border">
            <span className="sr-only"></span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Loading
