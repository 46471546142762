import { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { translate } from '../../../localization/localizationUtils'
import StockListModel from '../../../types/models/Stock/StockListModel'

type Props = {
  items: StockListModel[]
  onAmountChange: (
    amountNew: number,
    stockId?: string,
    cardId?: string,
    cardIdExt?: string,
    x?: number,
    y?: number
  ) => void
}

const NewStockNoteTable = ({ items, onAmountChange }: Props) => {
  const [rows, setRows] = useState<StockListModel[]>(items)

  useEffect(() => {
    setRows(items)
  }, [items])
  return (
    <div id="bnf-table-wrapper" className="h-100 flex-scrollable-container">
      <section className="h-100 overflow-auto">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>
                <div>{translate('stock-Name')}</div>
              </th>
              <th className="text-end">
                <div>{translate('stockNoteItem-Amount')}</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {rows.map((i, index) => (
              <tr key={index}>
                <td>{i.nameFull}</td>
                <td>
                  <div className="d-flex align-items-center justify-content-end">
                    <Form.Control
                      type="number"
                      value={i.amountInput}
                      onChange={(e) =>
                        onAmountChange(
                          Number(e.target?.value),
                          i.stockId,
                          i.cardId,
                          i.cardIdExt,
                          i.x,
                          i.y
                        )
                      }
                      style={{ width: 'auto' }}
                    />
                    <span className="ms-1 text-darker">
                      {translate('stockNoteItem-Disp', {
                        disp: i.amountDisp?.toString() || '',
                      })}
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </div>
  )
}

export default NewStockNoteTable
