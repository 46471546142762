import React from 'react'
import BnfTable from '../../../comps/BnfTable/BnfTable'
import IBnfTableDefColumn from '../../../comps/BnfTable/IBnfTableDefColumn'
import StockListModel from '../../../types/models/Stock/StockListModel'

type Props = {
  items: StockListModel[]
}

const ReclamationStockNoteTable = ({ items }: Props) => {
  const tableDef: Array<IBnfTableDefColumn> = [
    {
      caption: 'stock-Name',
      fieldName: 'nameFull',
      filterType: 'none',
    },
    {
      caption: 'stockNoteItem-XLabel',
      fieldName: 'xLabel1',
      filterType: 'none',
      valueFormat: ({ cell }: any) => {
        const xLabel = cell.row?.values?.xLabel1
        const xDesc = cell.row?.values?.xDescription1

        if (xDesc && xDesc !== '') return xDesc
        return xLabel
      },
    },
    {
      caption: 'stockNoteItem-XDescription',
      fieldName: 'xDescription1',
      isVisible: false,
    },
    {
      caption: 'stockNoteItem-Amount',
      fieldName: 'amountInput',
      filterType: 'none',
      sortType: 'num',
      align: 'right',
    },
  ]

  return <BnfTable source={items} columnsDef={tableDef} />
}

export default ReclamationStockNoteTable
