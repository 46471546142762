import React from 'react'
import BnfTable from '../../comps/BnfTable/BnfTable'
import IBnfTableDefColumn from '../../comps/BnfTable/IBnfTableDefColumn'
import PartnerAddressListModel from '../../types/models/Partners/PartnerAddressListModel'

type Props = {
  addresses: PartnerAddressListModel[]
}

const PartnerAddressesTable = ({ addresses }: Props) => {
  const tableDef: Array<IBnfTableDefColumn> = [
    {
      caption: 'partnerAddresses-Street',
      fieldName: 'street',
    },
    {
      caption: 'partnerAddresses-City',
      fieldName: 'city',
    },
    {
      caption: 'partnerAddresses-Zip',
      fieldName: 'zip',
    },
    {
      caption: 'partnerAddresses-Country',
      fieldName: 'countryId',
      filterType: 'select',
    },
    {
      caption: 'partnerAddresses-Email',
      fieldName: 'email',
    },
    {
      caption: 'partnerAddresses-Phone',
      fieldName: 'phone',
    },
    {
      caption: 'partnerAddresses-Contact',
      fieldName: 'contact',
    },
  ]

  return <BnfTable source={addresses} columnsDef={tableDef} />
}

export default PartnerAddressesTable
