import axios from 'axios'
import React, { useContext } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import PrivateRoute from '../comps/PrivateRoute'
import NotificationsContext from '../contexts/NotificationsContext/NotificationsContext'
import MainLayout from '../layouts/MainLayout'
import { translate } from '../localization/localizationUtils'
import Email from '../pages/Email/Email'
import Home from '../pages/Home/Home'
import Login from '../pages/Login/Login'
import Partners from '../pages/Partners/Partners'
import ReclamationDetail from '../pages/Reclamations/Detail/ReclamationDetail'
import Reclamations from '../pages/Reclamations/Reclamations'
import Stock from '../pages/Stock/Stock'
import {
  NotificationModel,
  NotificationType,
} from '../types/models/NotificationModel'

const axiosContext = { addNotification: (n: NotificationModel) => {} }

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (axios.isCancel(error)) return Promise.reject(error)
    if (error.response) {
      axiosContext.addNotification({
        type: NotificationType.Danger,
        name: translate('notifications-Error'),
        text:
          error.response?.message ||
          error.response?.statusText ||
          translate('notifications-UnexpectedErrorOccured'),
      })
    } else {
      axiosContext.addNotification({
        type: NotificationType.Danger,
        name: translate('notifications-Error'),
        text: translate('notifications-UnexpectedErrorOccured'),
      })
    }
    return Promise.reject(error)
  }
)

const Router = () => {
  const { addNotification } = useContext(NotificationsContext)

  axiosContext.addNotification = addNotification

  return (
    <BrowserRouter>
      <MainLayout>
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          ></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route
            path="/partners"
            element={
              <PrivateRoute>
                <Partners />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/reclamations"
            element={
              <PrivateRoute>
                <Reclamations />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/reclamations/detail/:year/:range/:recId"
            element={
              <PrivateRoute>
                <ReclamationDetail />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/email/:templateId/:pk/:to"
            element={
              <PrivateRoute>
                <Email />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/stock"
            element={
              <PrivateRoute>
                <Stock />
              </PrivateRoute>
            }
          ></Route>
        </Routes>
      </MainLayout>
    </BrowserRouter>
  )
}

export default Router
