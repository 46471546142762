import { createContext } from 'react'
import UserModel from '../../types/models/Login/UserModel'

interface IUserContext {
  user: UserModel | null
  login: (emial: string, password: string) => Promise<void>
  logout: () => void
}

const UserContext = createContext<IUserContext>({
  user: null,
  login: () => Promise.resolve(undefined),
  logout: () => undefined,
})

export default UserContext
