import React, { useState } from 'react'
import { FaArrowLeft, FaChevronDown } from 'react-icons/fa'
import { NavLink, useLocation } from 'react-router-dom'
import { translate } from '../../localization/localizationUtils'
import { NavItem, NavigationItems } from '../../utils/NavigationItems'
import './SideMenu.scss'

const NavigationItem = ({ label, icon, to, children }: NavItem) => {
  if (children)
    return (
      <NavigationItemHeader
        label={label}
        icon={icon}
        to={to}
        children={children}
      />
    )

  return (
    <NavLink to={to} className="nav-item">
      <span className="nav-icon">{icon}</span>
      <span className="nav-label">{translate(label)}</span>
    </NavLink>
  )
}

const NavigationItemHeader = ({ label, icon, to, children }: NavItem) => {
  const location = useLocation()
  const [expanded, setExpanded] = useState(location.pathname?.includes(to))

  const onExpandChange = (e: any) => {
    e.preventDefault()
    setExpanded((expanded) => !expanded)
  }

  return (
    <>
      <button className="nav-item nav-item-header-btn" onClick={onExpandChange}>
        <span className="nav-icon">{icon}</span>
        <span className="nav-label">{translate(label)}</span>
        <FaChevronDown
          className={`nav-chevron ${expanded ? 'expanded' : ''}`}
        />
      </button>

      {expanded && (
        <div className="nav-children">
          {children?.map((item, index) => {
            const key = `${item.label}-${index}`
            if (item.children) {
              return (
                <div key={key}>
                  <NavigationItemHeader
                    label={item.label}
                    icon={item.icon}
                    to={item.to}
                    children={item.children}
                  />
                </div>
              )
            }
            return (
              <NavLink key={key} to={item.to} className="nav-item">
                <span className="nav-icon">{item.icon}</span>
                <span className="nav-label">{translate(item.label)}</span>
              </NavLink>
            )
          })}
        </div>
      )}
    </>
  )
}

const SideMenu = () => {
  const [condensed, setCondensed] = useState<boolean>(false)

  return (
    <div id="side-menu-wrapper" className={`${condensed ? 'condensed' : ''}`}>
      <nav>
        <div className="nav-condense">
          <span
            role="button"
            className={`condense-icon ${condensed ? 'condensed' : ''}`}
            onClick={() => setCondensed(!condensed)}
          >
            <FaArrowLeft />
          </span>
        </div>
        {NavigationItems.map((n, index) => (
          <React.Fragment key={index}>
            <NavigationItem
              label={n.label}
              icon={n.icon}
              to={n.to}
              children={n.children}
            />
          </React.Fragment>
        ))}
      </nav>
    </div>
  )
}

export default SideMenu
