import { differenceInSeconds } from 'date-fns'
import { useEffect, useState } from 'react'
import ToastNotifications from '../../comps/ToastNotifications/ToastNotifications'
import {
  NotificationModel,
  NotificationType,
} from '../../types/models/NotificationModel'
import NotificationsContext from './NotificationsContext'

type Props = {
  children: React.ReactNode
}

const getTimeToLive = (type: NotificationType): number => {
  switch (type) {
    case NotificationType.Success: {
      return 5
    }
    case NotificationType.Warning: {
      return 7
    }
    case NotificationType.Danger: {
      return 10
    }
  }
}

const NotificationsProvider = ({ children }: Props) => {
  const [notifications, setNotifications] = useState<NotificationModel[]>([])
  const [, setIndexCounter] = useState<number>(0)
  const [time, setTime] = useState<number>(Date.now())

  const clearNotifications = () => {
    setNotifications([])
  }

  const addNotification = (notification: NotificationModel) => {
    setIndexCounter((i) => {
      setNotifications(() => [
        ...notifications,
        {
          ...notification,
          id: i,
          timeAdded: Date.now(),
          timeToLive: getTimeToLive(notification.type),
        },
      ])
      return i + 1
    })
  }

  const removeNotification = (id?: number) => {
    setNotifications((oldNotifications) =>
      oldNotifications.filter((n) => n.id !== id)
    )
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(Date.now())
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  })

  useEffect(() => {
    notifications.forEach((n) => {
      const aliveFor = differenceInSeconds(time, n.timeAdded || new Date())
      if (aliveFor >= (getTimeToLive(n.type) || 1)) {
        removeNotification(n.id)
      }
    })
  }, [time, notifications])

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        clearNotifications,
        addNotification,
        removeNotification,
      }}
    >
      {children}
      <ToastNotifications />
    </NotificationsContext.Provider>
  )
}

export default NotificationsProvider
