import { useState } from 'react'
import AuthService from '../../services/AuthService'
import UserModel from '../../types/models/Login/UserModel'
import UserContext from './UserContext'

type Props = {
  children: React.ReactNode
}

const UserProvider = ({ children }: Props) => {
  const [user, setUser] = useState<UserModel | null>(AuthService.getUser())

  const login = async (email: string, password: string) => {
    const u = await AuthService.login(email, password)
    if (u) setUser(u)
  }

  const logout = () => {
    AuthService.logout()
    setUser(null)
  }

  return (
    <UserContext.Provider
      value={{
        user,
        login,
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
